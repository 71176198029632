import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/views/LandingpageView.vue"),
    meta: { title: "Mieyar" },
    children: [
      {
        path: "/",
        component: () => import("@/views/LandingpageView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Mieyar";
  next();
});

export default router;
