<template>
  <router-view />
</template>

<script>
import { ApiService } from '@/services/ApiService';

export default {
  name: "App",
  data() {
    return {
      api: new ApiService()
    }
  },
};
</script>
